import seahorse from '../assets/seahorse.jpg'
import solanaweb3 from '../assets/solana-web3js.jpg'
import python from '../assets/python.jpg'
import resume from '../assets/DanielAlteonResume.pdf'


const INFO = {
	main: {
		title: "DRA Portfolio",
		name: "Daniel A.",
		email: "dalteon@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/inventandlearn",
		linkedin: "https://www.linkedin.com/in/invent2learn/",
		resume: resume,
	},

	homepage: {
		title: "DevOps Engineer",
		description:
			"A motivated DevOps Engineer with over two years of hands-on experience" +
			" in implementing DevOps practices and technologies. " +
            "My strong foundation in software development, infrastructure automation," +
			" and crafting CI/CD pipelines has been diligently cultivated through personal projects," +
			" a robust bootcamp, and self-guided learning. This proficiency extends to" +
			" cloud platforms, configuration management, and containerization, all leveraged to" +
			" facilitate smooth code integration and promote collaboration within development and operations teams.",
	},

	about: {
		title: "Unveiling the Journey of Daniel Alteon",
		description:
			"Embarking on my journey as a Junior to Mid-tier DevOps Engineer, I bring a blend of passion, curiosity, and a hunger for innovation to the table. With each challenge, I eagerly dive into the world of automation, cloud infrastructure, and continuous integration/deployment, fueling my quest to streamline processes and drive transformative change. As I navigate through this dynamic field, I am committed to mastering new technologies, collaborating with diverse teams, and crafting solutions that propel organizations to new heights of success. Join me as I embark on this exhilarating adventure at the intersection of development and operations!",
	},

	articles: {
		title: "Navigating the Blockchain Frontier: My Passion for Web 3.0 and Tokenized Assets",
		description:
			"Here are a few articles that highlight the transformation that is taking place through the tokenization of real-world assets, with big institutions climbing on board.",
	},

	projects: [
		{
			title: "Solana Calculator",
			description:
				" A Solana program that allows users to interact with the blockchain through calculations involving subtraction, multiplication, addition, and division. This program was built using Seahorse, a framework that lets you write Solana programs in Python. Furthermore,  I crafted a GitLab CI/CD pipeline " +
				" that takes the source code through four different stages: build, test, push, and deploy. The final deploy stage migrates the project to an AWS ECS with Terraform. ",
			logo: seahorse,
			linkText: "View Demo",
			link: "https://github.com/inventandlearn/seahorse-calculator-solana",
		},

		{
			title: "PoS Blockchain Prototype",
			description:
				" A proof of stake blockchain network written in python that enables users to stake, exchange, and transfer tokens." +
				" Additionally, a CI/CD pipeline was established with GitLab to deploy the blockchain to a Minikube cluster for testing and an AWS EKS cluster for production via Terraform.",
			logo: python,
			linkText: "View Demo",
			link: "https://github.com/inventandlearn/Python_PoS_Blockchain",
		},

		{
			title: "Solana Counter DApp With FrontEnd",
			description:
				"A counter program with a responsive front end UI scaffold/template that interacts with the Solana blockchain. This program allows users to increment, set, and decrement numbers; " +
				"each function triggers a transaction that is facilitated by a Phantom wallet or Backpack wallet chrome extension. The program was then deployed to AWS ECS via Terraform. ",
			logo: solanaweb3,
			linkText: "View Demo",
			link: "https://github.com/inventandlearn/solana-counter-frontend-dapp",
		},
	],
};

export default INFO;
