function article_1() {
	return {
		date: "03/21/2024",
		title: "BlackRock's $10 Trillion Tokenization Vision: The Future Of Real World Assets",
		description:
			"Wall Street and retail investors are looking at tokenized real world assets: bonds, equity, and real estate, on blockchain.",
	};
}

function article_2() {
	return {
		date: "11/18/2023",
		title: "Tokenized real-world assets are more than a Web3 Robinhood",
		description:
			"This is our chance for Web3 to drive actual decentralization, not just cosplay at TradFi with some blockchain sprinkled on top.",
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
