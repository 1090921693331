import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";

import "./styles/about.css";

import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import docker from '../assets/docker.png';
import jenkins from '../assets/jenkins.png';
import bash_scripting from '../assets/bash_scripting.png';
import linux from '../assets/Linux.png';
import insomnia from '../assets/insomnia.png';
import aws from '../assets/aws.png';
import restapi from '../assets/restapi.png';
import vagrant from '../assets/vagrant.png';
import kubernetes from '../assets/kubernetes.png';
import solana from '../assets/solana-web3js.jpg';
import postgresql from '../assets/postgresql.png';
import python from '../assets/python.png';
import reactframework from '../assets/react.png';
import github_actions from '../assets/github_actions.png';
import css from '../assets/css.png';
import terraform from '../assets/terraform.png';
import github from '../assets/github.png';
import django from '../assets/django.png';
import gitlab from '../assets/gitlab.jpg';

const tech = [
    {
        id: 1,
        src: html,
        title: 'HTML',
    },
    {
        id: 2,
        src: css,
        title: 'CSS',
    },
    {
        id: 3,
        src: javascript,
        title: 'Javascript',
    },
    {
        id: 4,
        src: reactframework,
        title: 'React',
    },
    {
        id: 5,
        src: github,
        title: 'Github',
    },
    {
        id: 6,
        src: docker,
        title: 'Docker',
    },
    {
        id: 7,
        src: jenkins,
        title: 'Jenkins',
    },
    {
        id: 8,
        src: bash_scripting,
        title: 'Bash Scripting',
    },
    {
        id: 9,
        src: linux,
        title: 'Linux',
    },
    {
        id: 10,
        src: insomnia,
        title: 'Insomnia',
    },
    {
        id: 11,
        src: aws,
        title: 'AWS',
    },
    {
        id: 12,
        src: restapi,
        title: 'RESTAPI',
    },
    {
        id: 13,
        src: vagrant,
        title: 'Vagrant',
    },
    {
        id: 14,
        src: kubernetes,
        title: 'Kubernetes',
    },
    {
        id: 15,
        src: solana,
        title: 'Solana Web3.js',
    },
    {
        id: 16,
        src: postgresql,
        title: 'PostgreSQL',
    },
    {
        id: 17,
        src: python,
        title: 'Python',
    },
    {
        id: 18,
        src: github_actions,
        title: 'Github Actions',
    },
    {
        id: 19,
        src: terraform,
        title: 'Terraform',
    },
    {
        id: 20,
        src: django,
        title: 'Django',
    },
    {
        id: 21,
        src: gitlab,
        title: 'GitLab',
    },
];

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <React.Fragment>
            <Helmet>
                <title>{`About | ${INFO.main.title}`}</title>
            </Helmet>

            <div className="page-content">
                <NavBar active="about" />
                <div className="content-wrapper">
                    <div className="about-logo-container">
                        <div className="about-logo">
                            <Logo width={46} />
                        </div>
                    </div>

                    <div className="about-container">
                        <div className="about-main">
                            <div className="about-right-side">
                                <div className="title about-title">
                                    {INFO.about.title}
                                </div>

                                <div className="subtitle about-subtitle">
                                    {INFO.about.description}
                                </div>
                            </div>

                            <div className="about-left-side">
                                <div className="about-image-container">
                                    <div className="about-image-wrapper">
                                        <img
                                            src="about.jpg"
                                            alt="about"
                                            className="about-image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Technologies Section */}
                        <div className="technologies-container">
                            <div className="title technologies-title">Technologies</div>
                            <div className="technologies-list">
                                {tech.map((item) => (
                                    <div key={item.id} className={`tech-item ${item.style}`}>
                                        <img src={item.src} alt={item.title} className="tech-icon" />
                                        <div className="tech-title">{item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    <div className="page-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default About;